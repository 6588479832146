@import './ConstantsAndMixins.scss';

@font-face {
  font-family: 'adlam';
  src: local('ADLaM_Display'), url(./Resources/ADLaM_Display/ADLaMDisplay-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'montserrat';
  src: local('Montserrat'), url(./Resources/Montserrat/Montserrat-Regular.ttf) format('truetype');
}

html, body, #root {
  height: 100%;
  font-family: 'Montserrat';
}

.app {
  height: 100%;
  width: 100%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  margin: 0.25rem 1rem;
  margin-bottom: 1rem;

  .potaco-logo {
    border-radius: 10px;
    height: 100%;

    @include smallScreen {
      height: 75%;
    }
  }

  .title {
    font-family: 'montserrat';
    letter-spacing: 2px;
    font-size: 2rem;
    text-align: center;
    font-weight: bold;

    @include smallScreen {
      font-size: 1rem;
    }
  }

  .icons {
    display: flex;

    a {
      margin-left: 5px;
    }
  }
}

.about {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 2rem);
  margin: 1rem;

  p {
    margin: 5px 0;
    text-align: center;

    @include smallScreen {
      font-size: 0.8rem;
    }
  }

  .family-photo {
    border-radius: 50%;
    height: 150px;
    margin-left: 10px;
  }
}

.games {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .row {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;

    @include mediumScreen {
      flex-direction: column;
    }

    @include smallScreen {
      flex-direction: column;
    }

    .game-img {
      display: flex;
      justify-content: center;
      padding: 20px;
      width: 600px;
      box-sizing: border-box;

      img {
        width: 560px;
        object-fit: cover;
        border-radius: 20px;

        &:hover {
          opacity: 0.8;
          cursor: pointer;
        }

        @include smallScreen {
          width: 350px;
        }
      }
    }

    .description {
      padding: 20px;
      box-sizing: border-box;
      width: 400px;
      text-align: center;

      a {
        font-weight: bold;
      }

      @include mediumScreen {
        padding-top: 0;
      }

      @include smallScreen {
        padding-top: 0;
      }
    }
  }
}