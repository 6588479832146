$small-screen-min: 300px;
$small-screen-max: 700px;
$medium-screen-max: 1000px;

@mixin smallScreen {
    @media screen and (max-width: $small-screen-max) { @content; }
}

@mixin mediumScreen {
    @media screen and (max-width: $medium-screen-max) and (min-width: ($small-screen-max + 1px)) { @content; }
}

@mixin largeScreen {
    @media screen and (min-width: ($medium-screen-max + 1px)) { @content; }
}